'use client';

import React from 'react';
import type { FelaWithThemeProps } from 'react-fela';
import {
  withTheme as felaWithTheme,
  RendererProvider,
  RendererContext,
  ThemeContext,
} from 'react-fela';
import type { DSC } from '@mentimeter/ragnar-dsc';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import type { TKeyFrame, TRule } from 'fela';

export type { IRenderer } from 'fela';
/*
 * Fela plugins
 */
export { default as fallback } from 'fela-plugin-fallback-value';
export { default as prefixer } from 'fela-plugin-prefixer';

export function useRagnar() {
  const renderer = React.useContext(RendererContext);
  const theme: DSC =
    React.useContext<DSC>(ThemeContext as any) || designSystemConfig;

  if (!renderer) {
    throw new Error(
      'The "useRagnar" hook can only be used  inside a "RendererProvider"',
    );
  }

  const css = React.useCallback(
    (rule: TRule<DSC>): string => renderer.renderRule(rule, theme),
    [theme, renderer],
  );

  const keyframe = React.useCallback(
    (keyframe: TKeyFrame<DSC>): string =>
      renderer.renderKeyframe(keyframe, theme),
    [theme, renderer],
  );

  return {
    keyframe,
    renderer,
    theme,
    css,
  };
}

export interface ThemeProps {
  theme: DSC;
}
export const withTheme = <Props>(
  Component: React.ComponentType<FelaWithThemeProps<DSC> & Props>,
): React.ComponentType<Omit<Props, 'theme'>> => felaWithTheme(Component as any);

export { createRenderer, combineRules } from 'fela';

export { render, rehydrate, renderToMarkup, renderToSheetList } from 'fela-dom';

export { createComponent, FelaComponent, renderToNodeList } from 'react-fela';

export { ThemeProvider } from './ThemeProvider';

export { RendererContext, ThemeContext, RendererProvider };

// backwards compatibility
const Provider = RendererProvider;
export { Provider };
